<!--
File: WorkCategoryEditForm.vue
Description: form for adding/editing a single Work Category.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <md-field :class="getClass('description')">
        <label for="description">{{ $t('stdCols.name') }} ({{ ($t('translate.ru')) }})</label>
        <md-input id="description" v-model="description" type="text" data-vv-name="description"
          v-validate="modelValidations.description" required />
      </md-field>

      <md-field :class="getClass('description_en')">
        <label for="description_en">{{ $t('stdCols.name') }} ({{ ($t('translate.en')) }})</label>
        <md-input id="description_en" v-model="description_en" type="text" data-vv-name="description_en"
          v-validate="modelValidations.description_en" required />
      </md-field>

      <md-field :class="getClass('reason')">
        <label for="reason">{{ $t('label.reason') }} ({{ ($t('translate.ru')) }})</label>
        <md-input id="reason" v-model="reason" type="text" data-vv-name="reason" v-validate="modelValidations.reason" />
      </md-field>

      <md-field :class="getClass('reason_en')">
        <label for="reason_en">{{ $t('label.reason') }} ({{ ($t('translate.en')) }})</label>
        <md-input id="reason_en" v-model="reason_en" type="text" data-vv-name="reason_en"
          v-validate="modelValidations.reason_en" />
      </md-field>

    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'

  export default {
    name: 'work-category-edit-form',
    mixins: [permissions, messages],

    data() {
      return {
        formName: 'WorkCategoryEditForm',
        eligible: false,

        description: null,
        description_en: null,
        reason: null,
        reason_en: null,

        initialFormState: null,

        modelValidations: {
          description: { required: true, min: 3 },
          description_en: { required: true, min: 3 },
          reason: { required: false, min: 3 },
          reason_en: { required: false, min: 3 },
        }
      }
    },

    props: {
      itemId: null
    },

    components: {
      Modal,
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.$emit('close')
        return
      };

      if (this.itemId) {
        // Load data on existing treatment
        const workCategory = await this.loadWorkCategory(this.itemId)
        this.description = workCategory.description
        this.description_en = workCategory.description_en
        this.reason = workCategory.reason
        this.reason_en = workCategory.reason_en
      }

      this.$nextTick(() => {
        this.saveInitialState()
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadWorkCategory: 'LOAD_WORK_CATEGORY_BY_ID',
        addWorkCategory: 'ADD_NEW_WORK_CATEGORY',
        editWorkCategory: 'UPDATE_WORK_CATEGORY',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theItem = {
          description: this.description,
          description_en: this.description_en,
          reason: this.reason,
          reason_en: this.reason_en
        }

        let errDesc = '';
        let newId;
        const action = !this.itemId ? this.addWorkCategory : this.editWorkCategory;
        const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
        try {
          const res = await action(payload)
          newId = res?.work_category_id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close')
        await this.savedMessage(errDesc, this.$t('label.category'), this.description, this.description_en)
        this.highlightRow(newId)
      },

      saveInitialState() {
        this.initialFormState = {
          description: this.description,
          description_en: this.description_en,
          reason: this.reason,
          reason_en: this.reason_en
        }
      },
    },

    computed: {
      screenTitle() {
        return this.itemId ? this.$t('screen_titles.work_category_upd') : this.$t('screen_titles.work_category_add')
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.description !== this.initialFormState.description ||
          this.description_en !== this.initialFormState.description_en ||
          this.reason !== this.initialFormState.reason ||
          this.reason_en !== this.initialFormState.reason_en
      }
    }
  }
</script>